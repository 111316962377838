
import { computed, defineComponent, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import "@fullcalendar/core";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import locale from "@fullcalendar/core/locales/pt-br";

import CModal from "@/components/Modal.vue";
import PFullCalendar from "primevue/fullcalendar";
import PProgressBar from "primevue/progressbar";

import { compareDates, formatDateEn, formatDate } from "@/libs/utils";

const VAgendaLock = defineComponent({
  name: "VAgendaLock",
  components: { CModal, PFullCalendar, PProgressBar },
  setup() {
    useHead({ title: "Bloqueios na agenda | obmed" });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const _id = Number(route.params._id);
    const loading = reactive({ lock: false });

    const lockDays = computed(() => store.state.agenda.lockDays);
    const events = computed(() =>
      lockDays.value.map((lockDay) => ({
        id: lockDay.id,
        title: `${formatDate(lockDay.dt_inicio_bloqueio)} - ${formatDate(lockDay.dt_fim_bloqueio)}`,
        displayEventTime: false,
        eventTime: "false",
        startRecur: lockDay.dt_inicio_bloqueio || "",
        endRecur: lockDay.dt_fim_bloqueio || "",
        classNames: ["is-danger"],
      }))
    );

    function handleDateClick(event: any) {
      if (compareDates(event.dateStr))
        router.push({
          name: "agenda-lock-add",
          params: { _id },
          query: { date: event.dateStr },
        });
      else
        store.commit("addToast", {
          summary: "Escolha uma data posterior a atual",
          severity: "warn",
        });
    }

    function handleEventClick({ event }: any) {
      router.push({
        name: "agenda-lock-edit",
        params: { _id },
        query: { id: event.id },
      });
    }

    const options = ref({
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      initialDate: formatDateEn(new Date().toDateString()),
      headerToolbar: {
        left: "prev,next",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      editable: false,
      locale,
      eventClick: handleEventClick,
      dateClick: handleDateClick,
    });

    async function getAgendaLockDays() {
      loading.lock = true;
      await store.dispatch("getAgendaLockDays", { _id });
      loading.lock = false;
    }

    function handleClose() {
      router.back();
    }

    getAgendaLockDays();

    return { lockDays, loading, events, options, handleClose };
  },
});

export default VAgendaLock;
